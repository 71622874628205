document.addEventListener("DOMContentLoaded", function () {
    var carouselItems = document.querySelectorAll(".carousel");
    if (carouselItems.length > 0) {
        carouselItems.forEach(function (el) {
            $(el).owlCarousel({
                loop: el.querySelectorAll('.item').length > 0,
                margin: 20,
                donts: true,
                autoplay: false,
                responsiveClass: true,
                responsive: {
                    0: {
                        items: 1,
                        nav: true
                    },
                    1024: {
                        items: 3,
                        nav: true
                    }
                },
                onResized: function () {
                    updateSize();
                },
                onInitialized: function () {
                    updateSize();
                }
            });
        });
    }

    function updateSize() {
        var minHeight = parseInt(carouselItems[0].querySelector('.owl-item').style.height);
        carouselItems.forEach(function (carousel) {
            var items = carousel.querySelectorAll('.owl-item');
            items.forEach(function (item) {
                var thisHeight = parseInt(item.style.height);
                minHeight = Math.min(minHeight, thisHeight);
            });
        });
        document.querySelectorAll('blockquote').forEach(function (el) {
            el.style.height = minHeight + 'px';
        });
    }

    var winHeightPadded = window.innerHeight * 1.1;
    var features = document.querySelectorAll(".features > .feature-description:not(.ani-processed) div");

    function displayFeatures() {
        var scrollPosition = window.scrollY;
        features.forEach(function (el) {
            var offsetTop = el.getBoundingClientRect().top + scrollPosition;
            if (scrollPosition + winHeightPadded > offsetTop) {
                el.classList.add("ani-processed");
            }
        });
    }

    displayFeatures();

    window.addEventListener('scroll', function () {
        displayFeatures();
    });

    function scrollToSpecificPosition(selector) {
        var target = document.querySelector(selector);
        if (target) {
            var scrollPosition = target.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({
                top: scrollPosition,
                behavior: 'smooth'
            });
        }
    }

    setTimeout(function () {
        scrollToSpecificPosition('.item-specific-class');
    }, 500);

    // drag and drop file upload
    document.querySelectorAll(".drop-zone__input").forEach((inputElement) => {
        const dropZoneElement = inputElement.closest(".drop-zone");
        const promptElement = dropZoneElement.querySelector(".drop-zone__prompt");
      
        dropZoneElement.addEventListener("click", () => inputElement.click());
      
        inputElement.addEventListener("change", () => {
          if (inputElement.files.length) {
            promptElement.innerHTML = `${inputElement.files.length} files selected for upload.`
          }
        });
      
        ["dragover", "dragleave", "dragend", "drop"].forEach((eventType) => {
          dropZoneElement.addEventListener(eventType, (e) => {
            e.preventDefault();
            const isOver = eventType === "dragover";
            dropZoneElement.classList.toggle("drop-zone--over", isOver);

            if(e.dataTransfer.files.length) {
                inputElement.files = e.dataTransfer.files

                const event = new Event('change', { bubbles: true });
                inputElement.dispatchEvent(event);

                promptElement.innerHTML = `${e.dataTransfer.files.length} files selected for upload.`
            }
          });
        });
      });
      
});
